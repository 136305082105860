import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import * as api from '../../services/api'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import * as utils from '../../utils'

const FreePublication = ({ publication }) => {
  const [publicationSent, setPublicationSent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const storageKey = `freepublication:${publication.id}`

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('الرجاء ادخال الاسم'),
      email: Yup.string()
        .email('يجب ادخال عنوان الكتروني صحيح')
        .required('يجب ادخال عنوان الكتروني'),
    }),
    onSubmit: async ({ email, name }) => {
      setPublicationSent(null)
      setIsLoading(true)

      try {
        await api.getFreePublication({ email, name })
        setPublicationSent(true)
        utils.setLocalStorage(storageKey, email)
        trackCustomEvent({
          category: 'FreeMagazine',
          action: 'requested',
        })
      } catch (err) {
        setPublicationSent(false)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const publicationAlreadySent = utils.getLocalStorage(storageKey)

  return (
    <div key={publication.id} className="box is-shadowless ">
      <div className="columns">
        <div className="column is-8">
          <div className="media-content has-text-right">
            <div className="is-size-5">
              {publication.name}
              <span className="has-text-weight-bold"> (مجانا)</span>
            </div>
            <div className="content">{publication.description}</div>
            {publicationAlreadySent && (
              <p className="has-background-success has-text-weight-bold column">
                تم ارسال نسخة من الإصدارة الى {publicationAlreadySent}.
              </p>
            )}

            {!publicationAlreadySent && (
              <form
                style={{ maxWidth: '500px' }}
                onSubmit={formik.handleSubmit}
              >
                <div className="field">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Your full name / الاسم الكامل"
                      name="name"
                      disabled={isLoading}
                      {...formik.getFieldProps('name')}
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="help has-text-right is-danger">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      name="email"
                      className="input"
                      type="email"
                      disabled={isLoading}
                      placeholder="me@email.com"
                      {...formik.getFieldProps('email')}
                    />
                  </div>
                  <p className="help is-primary has-text-centered">
                    * تأكد من ادخال بريد الكتروني صحيح حيث سيتم ارسال الاصدارة
                    لهذا العنوان
                  </p>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="help  has-text-right is-danger">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <button
                  className={`button is-primary is-outlined is-fullwidth ${
                    isLoading ? 'is-loading' : ''
                  }`}
                  disabled={!formik.isValid}
                >
                  اطلب الإصدارة
                </button>
              </form>
            )}
          </div>
        </div>
        <div className="column is-4">
          <img
            src={publication.image && publication.image.url}
            alt={publication.name}
          />
        </div>
      </div>
    </div>
  )
}

FreePublication.propTypes = {
  publication: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
}

export default FreePublication
